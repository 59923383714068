// COLORS
$color-primary-1: #020320fa;
$color-primary-2: rgb(6, 4, 67);
$color-primary-3: rgba(15, 20, 109, 0.686);
$color-primary-4: rgb(4, 17, 199);
$color-primary-5: rgb(4, 21, 255);

$color-secondary-1: hsl(21, 97%, 49%);
$color-secondary-2: hsla(22, 100%, 41%, 0.92);
$color-secondary-3: hsla(21, 92%, 34%, 0.868);
$color-secondary-4: hsla(21, 93%, 21%, 0.816);
$color-secondary-5: hsla(21, 88%, 19%, 0.246);

$text-color-white: rgb(255, 255, 255);
$text-color-black: rgb(0, 0, 0);

$text-color-darkGray: rgb(30, 30, 30);
$text-color-lightGray: rgb(161, 166, 251);

// FONT-FAMILY
$font-family-primary:  var(--font-poppins);
$font-family-secondary: var(--font-open-sans);
$font-family-tertiary:  var(--font-roboto);
$font-family-partial:  Helvetica, Arial, sans-serif;

// Smaller devices
$screen-xss-smadev: 390px;

// Mobile breakpoint
$screen-xs-mb: 576px;

// Tablet breakpoint
$screen-sm-tab: 768px;

// Laptop breakpoint
$screen-md-lap: 1024px;

// Desktop breakpoint
$screen-lg-desk: 2880px;

// Large desktop breakpoint
$screen-xl-deskL: 3072px;

// speed Loading Animation
$speed: 2.5s;

// Z-INDEX
$z-index-1: 4;
$z-index-2: 8;
$z-index-3: 12;
$z-index-4: 16;
$z-index-5: 20;
