@import "./styles/variables";
@import "./prism.css";
@import "/public/prism/prism.css";
@import url('/prism/prism.css');

:root {
  --size: 250px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: $color-primary-1;
}

body::-webkit-scrollbar-thumb:hover {
  background: $color-secondary-1;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-family: $font-family-primary;

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: none;
  }

  img {
    width: 100%;
  }

  p {
    font-size: 1rem; /* 16px */
    line-height: 1.6;
  }

  button {
    outline: none;
    border: none;
    border-radius: 0.8rem;
    font-weight: 800;
    transition: all 0.2s linear;
    cursor: pointer;
    padding: 0.4rem;
    background: $color-secondary-1;
  }
}

::selection {
  background: $color-secondary-1;
  color: $color-primary-1;
}

::-moz-selection {
  background: $color-secondary-1;
  color: $color-primary-1;
}

// password input styling
.password {
  position: relative;

  .icon {
    position: absolute;
    top: 2.6rem;
    right: 1rem;
    cursor: pointer;
    font-weight: 800 !important;
    font-size: larger !important;
  }
}

// full width
.widthFull {
  width: 100%;
  background: #eee;
}

.wrapperClassName {
  color: #49664d;
  border: 1px solid rgb(161, 166, 251);
  box-shadow: 2px 2px 5px 2px solid rgb(161, 166, 251);
}

.editorClassName {
  padding: 0.3rem;
  min-height: 50vh;
  color: black;
}

.toolbarClassNames {
  > * {
    background: rgb(161, 166, 251);
    > * {
      background: rgb(161, 166, 251);
      > * {
        background: rgb(161, 166, 251);
      }
    }
  }
}

input {
  &:focus {
    background: linear-gradient(145deg, $color-primary-1, $color-primary-2);
    box-shadow: inset 0px 1px 2px $text-color-lightGray;
    color: $text-color-lightGray;
    font-size: $font-family-secondary;
  }
}

// PAGINATION CLASSES
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 1rem;
}

.pagination .page-num {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: normal;
  // color: $color-primary-1;
  border: 1px solid $color-primary-1;
  margin: 2px;
}

.pagination .page-num:hover {
  color: #fff;
  background-color: $color-primary-1;
}

.activePage {
  color: #fff !important;
  background-color: $color-primary-1 !important;
  height: 100%;
  border-radius: 50% !important;
}
// PAGINATION CLASSES END

// image upload- base64 style
.dropzone {
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
  h5 {
    border: none;
    border-radius: 0.8rem;
    font-weight: 800;
    transition: all 0.2s linear;
    cursor: pointer;
    padding: 0.8rem;
    background: $color-primary-1;
    color: $text-color-lightGray;
  }
}

// form error box
.formErrorBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  p {
    font-size: 0.6rem;
    color: $color-secondary-1;
  }
}

// REACT - ALERT - MODAL STYLING
/* Defining modal styles */
.modal {
  background: $color-primary-1;
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  position: relative;
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15.6px);
  -webkit-backdrop-filter: blur(15.6px);
  border: 1px solid rgba(255, 255, 255, 0.49);
  // media queries || form
  @media (max-width: $screen-xss-smadev) {
    max-width: 300px;
  }

  .close {
    position: absolute;
    top: 2px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    font-weight: 800;
    color: $color-secondary-1;
    transition: color 0.3s ease;
    &:hover {
      color: $color-secondary-3;
    }
  }
}

/* Defining modal overlay styles */
.popup-overlay,
.popup_overlay {
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 1);
  }
}